<template>
  <Toast />

  <Button label="Novos Dados" icon="pi pi-plus" class="p-button-success mr-2" @click="novosDados"
    style="margin: 20px 0;" />

  <DataTable exportFilename="relatorio[DADOSTECNICOS]" csvSeparator=";" ref="dt" :value="listadados"
    v-model:selection="selectedUnidades" dataKey="id" :paginator="true" :rows="5" :filters="filters"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[5, 10, 25]"
    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
    <template #header>
      <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 class="m-0">Gerenciar Dados</h5>
        <span class="">
          <Dropdown class="mr-2" filter id="status" v-model="id_usina" :options="usinas" optionLabel="label" optionValue="value"
            placeholder="Filtrar por Usina">
          </Dropdown>
          <Dropdown class="mr-2" id="status" v-model="mes" :options="meses" optionLabel="label" optionValue="value"
                  placeholder="Filtrar por Mês">
                </Dropdown>

                <Dropdown class="mr-2" id="status" v-model="ano" :options="anos" optionLabel="label" optionValue="value"
                  placeholder="Filtrar por Ano">
                </Dropdown>
          <Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-button-sm" @click="filtrar()" />
        </span>
        <span class="block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Buscar" />
        </span>
      </div>
    </template>

    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
      <template #body="slotProps">
        <span class="p-column-title">id</span>
        {{ slotProps.data.id }}
      </template>
    </Column>

    <Column field="sigla" header="Usina" :sortable="true">
      <template #body="slotProps">
        <span class="p-column-title">Usina</span>
        {{ slotProps.data.sigla }}
      </template>
    </Column>
    <Column field="mes_referencia" header="Mês Referência" :sortable="true">
      <template #body="slotProps">
        <span class="p-column-title">Mês Referência</span>
        {{ slotProps.data.mes_referencia }}
      </template>
    </Column>
    <Column headerStyle="min-width:10rem;">
      <template #body="slotProps">
        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDados(slotProps.data)" />
        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
          @click="apagarUnidadeModal(slotProps.data)" />
      </template>
    </Column>
  </DataTable>


  <Dialog v-model:visible="deleteDadosDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="usina">Apagar: <b>{{ dados.usina }}</b>?</span>
    </div>
    <template #footer>
      <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="deleteDadosDialog = false" />
      <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUnidade" />
    </template>
  </Dialog>


  <Dialog v-model:visible="dadosTecnicos" :style="{ width: '80%' }" header="Gerenciar Dados" :modal="true"
    class="p-fluid">
    <div class="grid">
      <div class="col-6">
        <div class="field">
          <label for="id_usina">Usina</label>
          <Dropdown class="mr-2" id="id_usina" filter v-model="dados.id_usina" :options="usinas" optionLabel="label"
            optionValue="value" placeholder="Filtrar por Usina">
          </Dropdown>
        </div>
      </div>
      <div class="col-2">
        <div class="field">
          <label for="mes_referencia">Mês Referência</label>
          <InputText type="date" id="mes_referencia" v-model.trim="dados.mes_referencia" autofocus />
        </div>
      </div>

      <div style="padding: 10px 0; width: 100%; border-top:1px solid #eee;font-weight: bold;">
        <h5 style="margin:0;">Tarifas</h5>
      </div>

      <div class="col-1">
        <div class="field">
          <label for="tusd_valor">TUSD (R$/MWh)</label>
          <InputText type="number" id="tusd_valor" v-model.trim="dados.tusd_valor" autofocus />
        </div>
      </div>
      <div class="col-1">
        <div class="field">
          <label for="te_valor">TE (R$/MWh)</label>
          <InputText type="number" id="te_valor" v-model.trim="dados.te_valor" autofocus />
        </div>
      </div>
      <div class="col-1">
        <div class="field">
          <label for="cusd_valor">CUSD (R$/kW)</label>
          <InputText type="number" id="cusd_valor" v-model.trim="dados.cusd_valor" autofocus />
        </div>
      </div>
      <div class="col-1">
        <div class="field">
          <label for="tusdg_valor">TUSD G (R$/kW)</label>
          <InputText type="number" id="tusdg_valor" v-model.trim="dados.tusdg_valor" autofocus />
        </div>
      </div>

      <div class="col-1">
        <div class="field">
          <label for="pis_cofins_porcentagem">PIS/COFINS (%)</label>
          <InputText type="number" id="pis_cofins_porcentagem" v-model.trim="dados.pis_cofins_porcentagem" autofocus />
        </div>
      </div>

      <div class="col-1">
        <div class="field">
          <label for="icms_porcentagem">ICMS (%)</label>
          <InputText type="number" id="icms_porcentagem" v-model.trim="dados.icms_porcentagem" autofocus />
        </div>
      </div>
      <hr />
      <div class="col-2">
        <div class="field">
          <label for="valor_por_credito">Valor por Crédito (R$/MWh)</label>
          <InputText type="number" id="valor_por_credito" v-model.trim="dados.valor_por_credito" autofocus />
        </div>
      </div>
      <div class="col-2">
        <div class="field">
          <label for="valor_por_credito">Índice de Reajuste O&M (agosto)</label>
          <InputText type="number" id="indice_reajuste_oem" v-model.trim="dados.indice_reajuste_oem" autofocus />
        </div>
      </div>
      <div style="padding: 10px 0; width: 100%; border-top:1px solid #eee;font-weight: bold;">
        <h5 style="margin:0;">Geração</h5>
      </div>

      <div class="col-3">
        <div class="field">
          <label for="energia_contratada_mwh">Energia Contratada (MWh/mês)</label>
          <InputText type="number" id="energia_contratada_mwh" v-model.trim="dados.energia_contratada_mwh" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="energia_injetada_mwh">Energia Injetada (MWh/mês)</label>
          <InputText type="number" id="energia_injetada_mwh" v-model.trim="dados.energia_injetada_mwh" autofocus />
        </div>
      </div>

      <div style="padding: 10px 0; width: 100%; border-top:1px solid #eee;font-weight: bold;">
        <h5 style="margin:0;">Faturamento</h5>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="valor_contrato">Valor do Contrato/Equipamento (R$)</label>
          <InputText type="number" id="valor_contrato" v-model.trim="dados.valor_contrato" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="fatura_ufv_valor">Fatura da UFV (R$)</label>
          <InputText type="number" id="fatura_ufv_valor" v-model.trim="dados.fatura_ufv_valor" autofocus />
        </div>
      </div>

      <div class="col-3">
        <div class="field">
          <label for="fatura_ufv_valor">Valor de Terra (R$)</label>
          <InputText type="number" id="fatura_ufv_valor" v-model.trim="dados.fatura_ufv_valor" autofocus />
        </div>
      </div>
      <div style="padding: 10px 0; width: 100%; border-top:1px solid #eee;font-weight: bold;">
        <h5 style="margin:0;">Reajuste Contrato/Equipamentos(IPCA)</h5>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="reajuste_dezembro_vinte">Data Base</label>
          <InputText type="date" id="reajuste_dezembro_vinte" v-model.trim="dados.data_base" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="valor_data_base">Valor Data Base</label>
          <InputText type="number" id="valor_data_base" v-model.trim="dados.valor_data_base" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="data_atualizacao">Data de Atualização</label>
          <InputText type="date" id="data_atualizacao" v-model.trim="dados.data_atualizacao" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="valor_data_atualizacao">Valor Data de Atualização</label>
          <InputText type="number" id="valor_data_atualizacao" v-model.trim="dados.valor_data_atualizacao" autofocus />
        </div>
      </div>

      <div style="padding: 10px 0; width: 100%; border-top:1px solid #eee;font-weight: bold;">
        <h5 style="margin:0;">Reajuste Terras</h5>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="data_base_terra">Data Base</label>
          <InputText type="date" id="data_base_terra" v-model.trim="dados.data_base_terra" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="valor_data_base_terra">Valor Data Base</label>
          <InputText type="number" id="valor_data_base_terra" v-model.trim="dados.valor_data_base_terra" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="data_atualizacao_terra">Data de Atualização</label>
          <InputText type="date" id="data_atualizacao_terra" v-model.trim="dados.data_atualizacao_terra" autofocus />
        </div>
      </div>
      <div class="col-3">
        <div class="field">
          <label for="valor_data_atualizacao_terra">Valor Data de Atualização</label>
          <InputText type="number" id="valor_data_atualizacao_terra" v-model.trim="dados.valor_data_atualizacao_terra" autofocus />
        </div>
      </div>
      
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
        @click="this.dadosTecnicos = false" />
      <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarDados" />
    </template>
  </Dialog>
</template>



  
<script>
import { FilterMatchMode } from "primevue/api";


export default {

  data() {
    return {
      anos: [
        { label: "", value: "" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },{ label: "2024", value: "2024" },{ label: "2025", value: "2025" },
        { label: "2024", value: "2024" }
      ],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      ano: "",
      mes: "",
      id_usina: "",
      dadosTecnicos: false,
      dados: {},
      listadados: [],
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" }
      ],
      prioridade: "",
      unidades: [],
      usinas: [],
      UsinaDialog: false,
      deleteDadosDialog: false,
      deleteUnidadesDialog: false,
      usina: {},
      selectedUnidades: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
      concessionarias: [],
      tipos_tensao: [],
      tipos_modalidades: [],
      tipos_tensao_fornecimento: [],
      classes_tarifaria: [],
      sub_classes: [],
    };
  },
  UnidadeService: null,
  created() {
    this.initFilters();
  },
  mounted() {

    this.listarUsinas();
    this.listarDados();

    //this.UnidadeService.getUnidades().then((data) => (this.Unidades = data));
  },
  methods: {
    novosDados() {
      this.dadosTecnicos = true;
    },
    filtrar() {
      let dados = {
        id_usina: this.id_usina,
        mes: this.mes,
        ano: this.ano,
      }
      this.msg = "Aguarde, listando resultados."
      this.axios
        .post("/usinas/dados/listar", dados)
        .then((response) => {
          console.log(response);

          this.listadados = response.data.dados;


        })
        .catch((error) => {
          console.log(error);
        });
    },
    trocarCidades() {
      console.log(this.usina.id_estado);
      this.listarCidades(this.usina.id_estado);
    },

    listarUsinas() {

      this.axios
        .post("/usinas/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["sigla"] };
            options.push(option);
          });
          this.usinas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    listarDados() {

      this.axios
        .post("/usinas/dados/listar")
        .then((response) => {

          this.listadados = response.data.dados;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    trocarStatus() {
      this.usina.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {

      this.dados = {};
      this.submitted = false;
      this.UsinaDialog = true;
    },
    hideDialog() {
      this.UsinaDialog = false;
      this.submitted = false;
    },
    salvarDados() {
      this.submitted = true;

      if (this.dados.id) {
        this.axios
          .post("/usinas/dados/editar", this.dados)
          .then(() => {
            this.dadosTecnicos = false;
            this.dados = {};

            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usina salvs com sucsso!",
              life: 3000,
            });

            this.listarDados();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });


      } else {

        this.axios
          .post("/usinas/dados/adicionar", this.dados)
          .then(() => {

            this.listarDados();

            this.dadosTecnicos = false;
            this.dados = {};

            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usina salva com sucsso!",
              life: 3000,
            });

            this.listarDados();
          })

          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editDados(dados) {
      this.dadosTecnicos = true;
      this.dados = { ...dados };
    },
    apagarUnidadeModal(dados) {
      this.dados = dados;
      this.deleteDadosDialog = true;
    },
    apagarUnidade() {
      this.axios
        .post("/usinas/dados/apagar", this.dados)
        .then(() => {
          this.deleteDadosDialog = false;
          this.dados = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Dados apagado com sucsso!",
            life: 3000,
          });
          this.listarDados();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.Unidades.length; i++) {
        if (this.Unidades[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteUnidadesDialog = true;
    },
    deleteSelectedUnidades() {
      let obj = this.selectedUnidades;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarUnidade();
            this.deleteDadosDialog = false;
            this.usina = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Dados apagado com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteUnidadesDialog = false;
      this.selectedUnidades = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Unidades apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
  
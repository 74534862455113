<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <!--<form method="post" id="download" action="http://127.0.0.1:8001/api/download" target="_blank">-->
          <form method="post" id="download" action="https://gdaudit.amee.com.br/api/public/api/download" target="_blank">
          <input type="hidden" id="arquivos" name="arquivos">
          <input type="hidden" id="ano" name="ano">
          <input type="hidden" id="mes" name="mes">
          <input type="hidden" id="id_usina" name="id_usina">
        </form>
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <!--<Button
                label="Nova Unidade"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <Button
                label="Apagar"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              />-->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success" @click="exportCSV($event)" />
            <Button label="Baixar Faturas PDF" icon="pi pi-upload" class="p-button-primary ml-3"
              @click="baixarFaturas($event)" v-if="this.mostrar_download" />
          </template>
        </Toolbar>

        <DataTable 
        :loading="loading" 
        exportFilename="relatorio[ENERGIA]" csvSeparator=";" :value="energia" ref="dt" :paginator="true"
          :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll"
          >
          <template #header>
            <div class="
                        flex flex-column
                        md:flex-row md:justify-content-between md:align-items-center
                      ">
              <h5 class="m-0">Energia Injetada</h5>
              <span class="">
                <Dropdown class="mr-2" id="status" v-model="id_usina" filter :options="usinas" optionLabel="label"
                  optionValue="value" placeholder="Filtrar por Usina">
                </Dropdown>

                <Dropdown class="mr-2" id="status" v-model="mes" :options="meses" optionLabel="label" optionValue="value"
                  placeholder="Filtrar por Mês">
                </Dropdown>

                <Dropdown class="mr-2" id="status" v-model="ano" :options="anos" optionLabel="label" optionValue="value"
                  placeholder="Filtrar por Ano">
                </Dropdown>

                <Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-button-sm" @click="filtrar()" :loading="loading"/>

              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>

            </div>
          </template>

          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
            style="white-space:nowrap">
            <template #body="{ data, field }">
              {{ field === 'price' ? formatCurrency(data[field]) : data[field] }}
            </template>
            <template #editor="{ data, field }">
              <template v-if="field == 'usina'">
                {{data.usina}}
                
              </template>
              <template v-else-if="field == 'tipo_de_tensao'">
                {{data.tipo_de_tensao}}
              </template>
              <template v-else-if="field == 'concessionaria'">
                {{data.concessionaria}}
              </template>
              <template v-else>
                <InputText v-model="data[field]" autofocus />
              </template>
            </template>
          </Column>
          <Column field="url" header="Url">
            <template #body="slotProps">
              <Button class="p-button-warning p-button-sm" @click="downloadUnico(slotProps.data.url)">Download</Button>
            </template>

          </Column>
          <template #empty> {{ msg }} </template>
        </DataTable>


        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidade">Apagar: <b>{{ unidade.inscricao_imobiliaria }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUnidade" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidades">Apagar unidades selecionadas?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      msg: "",
      usinas: [],
      id_usina: "",
      ano: "",
      mes: "",
      loading: false,
      anos: [
        { label: "", value: "" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },{ label: "2024", value: "2024" },{ label: "2025", value: "2025" }],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      columns: null,
      energia: [],
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
      ],
      mostrar_download: false,
      prioridade: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    };
  },
  productService: null,
  created() {
    this.initFilters();
    this.columns = [
      { field: "Data_Implantacao", header: "Data Implantação" },
      { field: "Numero_do_Cliente", header: "Número do Cliente" },
      { field: "Numero_da_Instalacao", header: "Número da Instalação" }, 
      { field: "identificacao", header: "Identificação" },
      { field: "concessionaria", header: "Concessionária" },
      { field: "tipo_de_tensao", header: "Tipo de Tensão" },
      { field: "usina", header: "Usina" },
      { field: "rateio", header: "Rateio" },
      { field: "Mes_Competencia", header: "Mês Competência" },
      { field: "Medidor", header: "Medidor" },
      { field: "Data_Leitura_Anterior", header: "Data Leitura Anterior" },
      { field: "Data_Leitura_Atual", header: "Data Leitura Atual" },
      { field: "Proxima_Leitura", header: "Próxima Leitura" },
      { field: "Data_de_Emissao", header: "Data de Emissão" },
      { field: "Data_de_Vencimento", header: "Data de Vencimento" },
      { field: "Custo_de_Disponibilidade_kWh", header: "Custo de Disponibilidade kWh" },
      { field: "Custo_de_Disponibilidade_R", header: "Custo de Disponibilidade (R$)" },
      { field: "Leitura_Anterior", header: "Leitura Anterior" },
      { field: "Leitura_Atual", header: "Leitura Atual" },
      { field: "Constante", header: "Constante" },
      { field: "Consumo_Registrado_kWh", header: "Consumo Registrado kWh" },
      { field: "Consumo_Faturado_kWh", header: "Consumo Faturado kWh" },
      
      { field: "Tarifa_TUSD_Consumo_com_imposto_R", header: "Tarifa TUSD Consumo c/ imposto R$" },
      { field: "Tarifa_TE_com_Consumo_imposto_R", header: "Tarifa TE c/ Consumo imposto R$" },
      { field: "Tarifa_Consumo_kWh_com_impostos_R", header: "Tarifa Cheia Consumo c/ impostos (R$)" },
      
      { field: "Valor_Consumo_kWh_R", header: "Valor Consumo kWh (R$)" },
      { field: "Energia_Compensada_kWh", header: "Energia Compensada kWh" },

      //campos novvos
      { field: "Tarifa_TUSD_Energia_Compensada_com_impostos_R", header: "Tarifa TUSD Energia Compensada c/ impostos R$" },
      { field: "Tarifa_TE_Energia_Compensada_com_impostos_R", header: "Tarifa TE Energia Compensada c/ impostos R$" },
      { field: "Tarifa_Energia_Compensada_R", header: "Tarifa Cheia Energia Compensada c/ impostos (R$)" },
     
  
      { field: "Valor_Energia_Compensada_R", header: "Valor Energia Compensada (R$)" },

      { field: "Energia_Compensada_2_kWh", header: "Energia Compensada 2 kWh" },
      { field: "Tarifa_TUSD_Energia_Compensada_2", header: "Tarifa TUSD Energia Compensada 2" },
      { field: "Tarifa_TE_Energia_Compensada_2", header: "Tarifa TE Energia Compensada 2" },
      { field: "Tarifa_Cheia_Energia_Compensada_2_com_impostos_R", header: "Tarifa Cheia Energia Compensada 2 c/ impostos (R$)" },
      { field: "Valor_Energia_Compensada_2_R", header: "Valor Energia Compensada 2 R$" },
      
      { field: "Energia_Compensada_3_kWh", header: "Energia Compensada 3 kWh" },
      { field: "Tarifa_TUSD_Energia_Compensada_3", header: "Tarifa TUSD Energia Compensada 3" },
      { field: "Tarifa_TE_Energia_Compensada_3", header: "Tarifa TE Energia Compensada 3" },
      { field: "Tarifa_Cheia_Energia_Compensada_3_com_impostos_R", header: "Tarifa Cheia Energia Compensada 3 c/ impostos (R$)" },
      { field: "Valor_Energia_Compensada_3_R", header: "Valor Energia Compensada 3 R$" },

      { field: "Energia_injetada_kWh", header: "Energia injetada kWh" },

      //campos novvos
      { field: "Tarifa_TUSD_Energia_Inejtada_com_impostos_R", header: "Tarifa TUSD Energia Injetada c/ impostos R$" },
      { field: "Tarifa_TE_Energia_Injetada_com_impostos_R", header: "Tarifa TE Energia Injetada c/ impostos R$" },      
      { field: "Tarifa_Energia_Injetada_R", header: "Tarifa Cheia Energia Injetada c/ impostos (R$)" },
      
      { field: "Valor_Energia_Injetada_R", header: "Valor Energia Injetada (R$)" },
      { field: "Saldo_Utilizado_no_Mes", header: "Saldo utilizado no mês" },
      { field: "Saldo_Atual_Geracao_Ponta", header: "Saldo Atual Geração Ponta" },
      { field: "Saldo_Atual_Geracao_F_Ponta", header: "Saldo Atual Geração F. Ponta" },
      { field: "Saldo_Atual_Geracao_Total", header: "Saldo Atual Geração Total" },
      { field: "Energia_Reativa_Excedente_kVARh", header: "UFER kVARh" },
      { field: "Tarifa_Energia_Reativa_com_impostos_R", header: "Tarifa UFER com impostos (R$)" },
      { field: "Energia_Reativa_Valor_", header: "UFER Valor (R$)" },
      { field: "Adicional_Bandeira_R", header: "Adicional Bandeira R$" },
      { field: "llumPubMunicipal_R", header: "llum.Pub.Municipal (R$)" },
      { field: "Devolucoes_R", header: "Devoluções (R$)" },
      { field: "Outras_Despesas_R", header: "Outras Despesas (R$)" },
      // { field : "Compensacao_DIC_Mensal_Data", header : "Compensação DIC Mensal (Data)" },
      // { field : "Compensacao_DIC_Mensal_R", header : "Compensação DIC Mensal (R$)" },
      // { field : "Compensacao_DMIC_Data", header : "Compensação DMIC (Data)" },
      // { field : "Compensacao_DMIC_R", header : "Compensação DMIC (R$)" },
      // { field : "Compensacao_DICRI_Mensal_Data", header : "Compensação DICRI Mensal (Data)" },
      // { field : "Compensacao_DICRI_Mensal_R", header : "Compensação DICRI Mensal (R$)" },
      { field: "Base_de_Calculo_ICMS_R", header: "Base de Cálculo ICMS (R$)" },
      { field: "Aliquota_ICMS_", header: "Alíquota ICMS %" },
      { field: "Valor_ICMS_R", header: "Valor ICMS R$" },
      { field: "Base_de_Calculo_PISPASEP_R", header: "Base de Cálculo PIS/PASEP R$" },
      { field: "Aliquota_PISPASEP_", header: "Alíquota PIS/PASEP %" },
      { field: "Valor_PISPASEP_R", header: "Valor PIS/PASEP R$" },
      { field: "Base_de_Calculo_COFINS_R", header: "Base de Cálculo COFINS R$" },
      { field: "Aliquota_COFINS_", header: "Alíquota COFINS %" },
      { field: "Valor_COFINS_R", header: "Valor COFINS R$" },
      { field: "Total_da_Fatura", header: "Total da Fatura" },

     //campos novos
      { field: "Tarifa_TUSD_Consumo_sem_imposto_R", header: "Tarifa TUSD Consumo sem imposto R$" },
      { field: "Tarifa_TE_sem_Consumo_imposto_R", header: "Tarifa TE s/ Consumo imposto R$" },
      { field: "Tarifas_Consumo_sem_impostos", header: "Tarifa Cheia Consumo s/ impostos (R$)" },
      
      //campos novos
      { field: "Tarifa_TUSD_Energia_Compensada_sem_imposto_R", header: "Tarifa TUSD Energia Compensada s/ imposto R$" },
      { field: "Tarifa_TE_com_Energia_Compensada_sem_imposto_R", header: "Tarifa TE Energia Compensada s/ imposto R$" },
      { field: "Tarifa_Energia_Compensada_sem_impostos", header: "Tarifa Cheia Energia Compensada s/ impostos (R$)" },

      { field: "Tarifa_TUSD_Energia_Compensada_2_s_imposto", header: "Tarifa TUSD Energia Compensada 2 s/ imposto" },
      { field: "Tarifa_TE_Energia_Compensada_2_s_imposto", header: "Tarifa TE Energia Compensada 2 s/ imposto" },
      { field: "Tarifa_Cheia_Energia_Compensada_2_s_imposto", header: "Tarifa Cheia Energia Compensada 2 s/ imposto" },
      { field: "Tarifa_TUSD_Energia_Compensada_3_s_imposto", header: "Tarifa TUSD Energia Compensada 3 s/ imposto" },
      { field: "Tarifa_TE_Energia_Compensada_3_s_imposto", header: "Tarifa TE Energia Compensada 3 s/ imposto" },
      { field: "Tarifa_Cheia_Energia_Compensada_3_s_imposto", header: "Tarifa Cheia Energia Compensada 3 s/ imposto" },

      { field: "Tarifas_Energia_Reativa_Excedente_sem_impostos", header: "Tarifa UFER (sem imposto)" },
    ];

  },
  mounted() {
    this.listarUsinas();
    //this.listarEnergia();
    this.msg = ""
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      console.log(data.id);
      console.log(newValue);
      console.log(field);

      let dados = {
        id: data.id,
        valor: newValue,
        campo: field
        
      }

      this.axios
        .post("/eum/editar", dados)
        .then((response) => {
          console.log(response);

          this.energia = response.data.dados;


          if (this.energia.length > 0) {
            this.mostrar_download = true;
          }
          else {
            this.msg = "Nenhum resultado encontrado."
          }

        })
        .catch((error) => {
          console.log(error);
        });
      
    },
    filtrar() {
      this.loading = true;
      let dados = {
        id_usina: this.id_usina,
        ano: this.ano,
        mes: this.mes
      }
      this.msg = "Aguarde, listando resultados."
      this.axios
        .post("/eum/energia", dados)
        .then((response) => {
          console.log(response);

          this.energia = response.data.dados;


          if (this.energia.length > 0) {
            this.mostrar_download = true;
          }
          else {
            this.msg = "Nenhum resultado encontrado."
          }
          this.loading = false;

        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    listarUsinas() {

      this.axios
        .post("/usinas/listar")
        .then((response) => {

          let options = [];

          let res = response.data.dados;
          Object.keys(res).forEach(ley => {
            console.log(ley);
            let option = { value: res[ley]["id"], label: res[ley]["razao_social"] };
            options.push(option);
          });


          this.usinas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarEnergia() {
      this.msg = 'Aguarde, listando resultados.';
      this.axios
        .post("/eum/energia")
        .then((response) => {
          console.log(response);

          this.energia = response.data.dados;

          if (this.energia.length == 0) {
            this.msg = "Nenhum resultado encontrado."
          }


        })
        .catch((error) => {
          console.log(error);
        });
    },


    trocarStatus() {
      this.unidade.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.unidade = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarUnidade() {
      this.submitted = true;
      if (this.unidade.id) {
        this.axios
          .post("/unidades/editar", this.unidade)
          .then(() => {
            this.listarUnidade();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/unidades/adicionar", this.unidade)
          .then(() => {
            this.listarUnidade();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(unidade) {
      this.productDialog = true;

      this.unidade = { ...unidade };
      this.listarCidades(this.unidade.id_estado);
    },
    apagarUnidadeModal(unidade) {
      this.unidade = unidade;
      this.deleteProductDialog = true;
    },
    apagarUnidade() {
      this.axios
        .post("/unidades/apagar", this.unidade)
        .then(() => {
          this.listarUnidade();
          this.deleteProductDialog = false;
          this.unidade = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Unidade apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async baixarFaturas() {

      document.getElementById("arquivos").value = '';
      document.getElementById("ano").value = this.ano;
      document.getElementById("mes").value = this.mes;
      document.getElementById("id_usina").value = this.id_usina;


      //  document.getElementById("arquivos").value = JSON.stringify(aux);
      document.getElementById("download").submit();

    },
    downloadUnico(url) {
      window.open(url);
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarUnidade();
            this.deleteProductDialog = false;
            this.unidade = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Unidades apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <form method="post" id="download" action="https://gdaudit.amee.com.br/api/public/api/download" target="_blank">   
           <!--      <form method="post" id="download" action="http://127.0.0.1:8000/api/download" target="_blank"> -->

          <input type="hidden" id="arquivos" name="arquivos">
          <input type="hidden" id="arquivos" name="arquivos">
          <input type="hidden" id="ano" name="ano">
          <input type="hidden" id="mes" name="mes">
          <input type="hidden" id="id_usina" name="id_usina">
        </form>
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <!--<Button
                label="Nova Unidade"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <Button
                label="Apagar"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              />-->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success" @click="exportCSV($event)" />
            <Button v-show="this.mostrar_download" label="Baixar Faturas PDF" icon="pi pi-upload"
              class="p-button-primary ml-3" @click="baixarFaturas($event)" />
          </template>
        </Toolbar>

        <DataTable exportFilename="relatorio[ENERGIA]" csvSeparator=";" :value="energia" ref="dt" :paginator="true"
          :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll" v-model:selection="selectedProducts">
          <template #header>
            <div class="
                                    flex flex-column
                                    md:flex-row md:justify-content-between md:align-items-center
                                  ">
              <h5 class="m-0">Faturas</h5>

              <span class="">
                <Dropdown class="mr-2" id="status" v-model="id_usina" :options="usinas" optionLabel="label"
                  optionValue="value" placeholder="Filtrar por Usina">
                </Dropdown>

                <Dropdown class="mr-2" id="status" v-model="mes" :options="meses" optionLabel="label" optionValue="value"
                  placeholder="Filtrar por Mês">
                </Dropdown>

                <Dropdown class="mr-2" id="status" v-model="ano" :options="anos" optionLabel="label" optionValue="value"
                  placeholder="Filtrar por Ano">
                </Dropdown>

                <Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-button-sm" @click="filtrar()" />

              </span>


              <span class="mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Filtrar Resultado" />
              </span>
            </div>
          </template>

          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
            style="white-space:nowrap"></Column>
          <Column field="url" header="Url">
            <template #body="slotProps">
              <Button class="p-button-warning p-button-sm" @click="downloadUnico(slotProps.data.url)">Download</Button>
            </template>

          </Column>
        </DataTable>




        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidades">Apagar unidades selecionadas?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      anos: [
        { label: "", value: "" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },{ label: "2024", value: "2024" },{ label: "2025", value: "2025" }],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      mostrar_download: false,
      columns: null,
      ano: "",
      mes: "",
      energia: [],
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
      ],
      prioridade: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      usinas: [],
      id_usina: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    };
  },
  productService: null,
  created() {
    this.initFilters();
    this.columns = [
      { field: "Numero_do_Cliente", header: "Número do Cliente" },
      { field: "Numero_da_Instalacao", header: "Número da Instalação" },
      { field: "concessionaria", header: "Concessionária" },
      { field: "tipo_de_tensao", header: "Tipo de Tensão" },
      { field: "usina", header: "Usina" },
      { field: "Mes_Competencia", header: "Mês Competência" },


      { field: "Data_de_Emissao", header: "Data de Emissão" },
      { field: "Data_de_Vencimento", header: "Data de Vencimento" },

    ];
  },
  mounted() {
    this.listarUsinas();
    this.listarEnergia();

    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    
    downloadUnico(url) {
      window.open(url);
    },
    filtrar() {
      let dados = {
        id_usina: this.id_usina,
        ano: this.ano,
        mes: this.mes
      }
      this.axios
        .post("/eum/energia", dados)
        .then((response) => {
          console.log(response);

          this.energia = response.data.dados;

          if (this.energia.length > 0) {
            this.mostrar_download = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarUsinas() {
      this.axios
        .post("/usinas/listar")
        .then((response) => {

          let options = [];

          let res = response.data.dados;
          Object.keys(res).forEach(ley => {
            console.log(ley);
            let option = { value: res[ley]["id"], label: res[ley]["razao_social"] };
            options.push(option);
          });


          this.usinas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarEnergia() {
      this.axios
        .post("/eum/energia")
        .then((response) => {
          console.log(response);

          this.energia = response.data.dados;


        })
        .catch((error) => {
          console.log(error);
        });
    },


    trocarStatus() {
      this.unidade.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.unidade = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarUnidade() {
      this.submitted = true;
      if (this.unidade.id) {
        this.axios
          .post("/unidades/editar", this.unidade)
          .then(() => {
            this.listarUnidade();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/unidades/adicionar", this.unidade)
          .then(() => {
            this.listarUnidade();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(unidade) {
      this.productDialog = true;

      this.unidade = { ...unidade };
      this.listarCidades(this.unidade.id_estado);
    },
    apagarUnidadeModal(unidade) {
      this.unidade = unidade;
      this.deleteProductDialog = true;
    },
    apagarUnidade() {
      this.axios
        .post("/unidades/apagar", this.unidade)
        .then(() => {
          this.listarUnidade();
          this.deleteProductDialog = false;
          this.unidade = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Unidade apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async baixarFaturas() {

      document.getElementById("arquivos").value = '';
      document.getElementById("ano").value = this.ano;
      document.getElementById("mes").value = this.mes;
      document.getElementById("id_usina").value = this.id_usina;
     
   
    //  document.getElementById("arquivos").value = JSON.stringify(aux);
     document.getElementById("download").submit();

    },
    
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarUnidade();
            this.deleteProductDialog = false;
            this.unidade = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Unidades apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>

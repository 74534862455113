<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable exportFilename="relatorio[FATURAS_IMPLANTADAS]" csvSeparator=";" ref="dt" :value="faturas" dataKey="id"
          :paginator="true" :rows="50"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
              <h5 class="m-0">Faturas Implantadas</h5>
              <span class="">
                <Dropdown class="mr-2" id="status" v-model="id_usina" :options="usinas" optionLabel="label"
                  optionValue="value" filter placeholder="Filtrar por Usina">
                </Dropdown>

                <div style="margin:10px 0;">

                  <Dropdown class="mr-2" id="status" v-model="dia" :options="dias" optionLabel="label" optionValue="value"
                    placeholder="Dia">
                  </Dropdown>
                  <Dropdown class="mr-2" id="status" v-model="mes" :options="meses" optionLabel="label"
                    optionValue="value" placeholder="Mês">
                  </Dropdown>
                  <Dropdown class="mr-2" id="status" v-model="ano" :options="anos" optionLabel="label" optionValue="value"
                    placeholder="Ano">
                  </Dropdown>
                  até
                  <Dropdown class="mr-2" id="status" v-model="dia_final" :options="dias" optionLabel="label" optionValue="value"
                    placeholder="Dia">
                  </Dropdown>
                  <Dropdown class="mr-2" id="status" v-model="mes_final" :options="meses" optionLabel="label"
                    optionValue="value" placeholder="Mês">
                  </Dropdown>
                  <Dropdown class="mr-2" id="status" v-model="ano_final" :options="anos" optionLabel="label" optionValue="value"
                    placeholder="Ano">
                  </Dropdown>

                </div>

                <Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-button-sm" @click="filtrar()"
                  :loading="loading" />

              </span>

            </div>
          </template>
          <Column field="Data_Implantacao" header="Mês Implantação" headerStyle="width:10%; min-width:10rem;">
          </Column>
          <Column field="Mes_Competencia" header="Mês Referência" headerStyle=" min-width:10rem;">
          </Column>

          

          <Column field="Numero_da_Instalacao" header="Unidade" headerStyle="width:14%; min-width:10rem;">
          </Column>

          <Column field="Numero_do_Cliente" header="Código Cliente" headerStyle="width:10%; min-width:10rem;">

          </Column>
          <Column field="concessionaria" header="Concessionária" headerStyle="width:20%; min-width:10rem;">

          </Column>

          <Column field="usina" header="Usina" headerStyle="width:30%; min-width:10rem;">

          </Column>

         



        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {

      anos: [
        { label: "", value: "" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },{ label: "2024", value: "2024" },{ label: "2025", value: "2025" }],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],

      dias: [
        { label: "", value: "" },
        { label: "1", value: "01" },
        { label: "2", value: "02" },
        { label: "3", value: "03" },
        { label: "4", value: "04" },
        { label: "5", value: "05" },
        { label: "6", value: "06" },
        { label: "7", value: "07" },
        { label: "8", value: "08" },
        { label: "9", value: "09" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        { label: "29", value: "29" },
        { label: "30", value: "30" },
        { label: "31", value: "31" },
      ],
      usinas: [],
      faturas: [],
      id_usina: 0,
      ano: "",
      mes: "",
      dia: "",
      ano_final: "",
      mes_final: "",
      dia_final: "",
      products: [],
      loading: false,
    };
  },
  created() {

  },
  mounted() {
    this.listarUsinas();
  },
  methods: {


    filtrar() {

      let dados = {
        id_usina: this.id_usina,
        dia: this.dia,
        mes: this.mes,
        ano: this.ano,

        dia_final: this.dia_final,
        mes_final: this.mes_final,
        ano_final: this.ano_final,
      };
      this.axios
        .post("/faturas/implantadas", dados)
        .then((response) => {

          this.faturas = response.data.dados;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    listarUsinas() {

      this.axios
        .post("/usinas/listar")
        .then((response) => {

          let options = [];

          let res = response.data.dados;
          Object.keys(res).forEach(ley => {
            console.log(ley);
            let option = { value: res[ley]["id"], label: res[ley]["razao_social"] };
            options.push(option);
          });


          this.usinas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },


  },
};
</script>